/**
 * hide scrollbars
 */
 ::-webkit-scrollbar {
  /* display: block;
  width: 0 !important; */
}
 .disable-scrollbars {
  /* scrollbar-width: 1px; */
  /* Firefox */
  /* -ms-overflow-style: none; */
  /* IE 10+ */
}
@media only screen and (max-width: 1025px){
  #root {
    width: 100%;
    min-width: 100%;
    height: 100%;
    min-height: 100%;
    padding: 0;
    margin: 0;
    overflow: auto;
    position: relative;
    
  }
  .custom-border-main{
    background: white;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    overflow: hidden;
    height: 91vh;
  }
}
@media only screen and (max-height: 700px){
 .tripTable{
  overflow: auto;
  min-height: 30vh;
 }

}
@media only screen and (min-height: 701px) and (max-height: 900px){
  .tripTable{
    overflow: auto;
    min-height: 30vh;
  }
  .trip-height-adjust{
    overflow:"scroll";height:"80vh";
  }
}
@media only screen and (min-height: 901px) and (max-height: 1200px){
  .tripTable{
    overflow: auto;
    min-height: 30vh;
  }
  .trip-height-adjust{
    overflow:"scroll";height:"81vh";
  }
}
@media only screen and (min-height: 1201px){
  .tripTable{
    overflow: auto;
    min-height: 30vh;
  }
  .trip-height-adjust{
    overflow:"scroll";height:"83vh";
  }
}

@media only screen and (min-width: 1026px){
  #root {
    width: 100%;
    min-width: 100%;
    height: 100%;
    min-height: 100%;
    padding: 0;
    margin: 0;
    overflow: auto;
    position: relative;
    
  }
  .custom-border-main{
    background:white;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    overflow:hidden;
  }
}

/**/
input[type="date"]::-webkit-datetime-edit, input[type="date"]::-webkit-inner-spin-button, input[type="date"]::-webkit-clear-button {
  color: #fff;
  position: relative;
}

input[type="date"]::-webkit-datetime-edit-year-field{
  position: absolute !important;
  border-left:1px solid #8c8c8c;
  padding: 2px;
  color:#000;
  left: 56px;
}

input[type="date"]::-webkit-datetime-edit-month-field{
  position: absolute !important;
  border-left:1px solid #8c8c8c;
  padding: 2px;
  color:#000;
  left: 26px;
}


input[type="date"]::-webkit-datetime-edit-day-field{
  position: absolute !important;
  color:#000;
  padding: 2px;
  left: 4px;
  
}

/**/
.link {
  @apply text-blue-500;
}

.shadow-outline-red {
  box-shadow: 0 0 0 3px theme("colors.red.300");
}
.shadow-outline-green {
  box-shadow: 0 0 0 3px theme("colors.green.300");
}
.shadow-outline-white {
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 1);
}
.shadow-outline-black {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 1);
}

.react-switch {
  vertical-align: middle;
  margin-left: 4px;
}

.react-icons {
  vertical-align: middle;
}
.starPosition{
  display: inline;
}
.z-1 {
  z-index: 1;
}
.z-2 {
  z-index: 2;
}
.bg-1 {
  background: url(/bg-login-1.jpg) no-repeat 0 0 fixed;
  background-size: cover;
}
.bg-login-2 {
  background: url(/bg-login-2.png) no-repeat 0 0 fixed;
  background-size: cover;
}
.bg-login-3 {
  background: url(/bg-login-2.png) no-repeat 0 0 fixed;
  background-size: cover;
}

.w-96 {
  width: calc(theme("spacing.1") * 96);
}
.w-128 {
  width: calc(theme("spacing.1") * 128);
}

.profile-height{
  margin-top: -20px;
}

.alert-error, .alert-success {
  display:flex;
  position: fixed !important;
  bottom: 30px;
  z-index: 100;
  right: 20px;
  width: 500px;
  font-size: 1rem;
  font-weight: 400;
  overflow: auto;
}

.bg-transparent.alert-error, .bg-transparent.alert-success {
  background-color: #ffffffa6 !important
}

@media only screen and (max-width: 767px){
  .screen-adjust-fimenu{
    display: none;
  }
  .screen-adjust-closeMenu{
    display: block;
    color: #000000;
    margin-left: 22px;
  }
  .adjust-small{    
    margin-bottom: 10px;
    margin-top: 10px;
  }

}
@media only screen and (min-width: 768px){
  .screen-adjust-fimenu{
    display: block;
  }
  .screen-adjust-closeMenu{
    display: none;
    color: #fff;
  }
}
@media only screen and (max-height: 820px){
  .table-container {
    overflow-y: auto;
    overflow-x: hidden;
     /* min-height: 20vh;  */
    height: 80vh;
  }
}
@media only screen and (min-height: 821px){
  .table-container {
    overflow-y: auto;
    overflow-x: hidden;
    /* min-height: 20vh; */
    height: 80vh;
  }
}
@media only screen and (min-width: 1025px){
  
	.fimenuhidder{
    display: none;
  }

  .fimenu{
    margin-left: -20px;
    margin-right: 20px;
    display: block;
  }
  .logo {
    width: 80%;
    margin: 0 auto;  
  }
  .logo_text{
    margin-left: -18px;
    color: #37708E;
    font-size: 18px;
  }
  
  
}

@media only screen and (min-width: 767px) and (max-width: 1025px){
  .fimenuhidder{
    display: none;
  }
}
@media only screen  and (max-width: 1025px){
	
  .fimenu{
    margin-left: 5px;
    margin-right: 20px;
    
  }
  .logo {
    width: 50%;
    margin: 0 auto;  
  }
  .logo_text{
   
    color: #37708E;
    font-size: 18px;
  }
}
/*@media only screen and (orientation: landscape) and (min-width: 759px) and (max-width:1025){
	.fimenuhidder{
    display: none;
  }
  .fimenu{
    margin-left: -20px;
    margin-right: 20px;
  }
}
@media only screen and (orientation: portrait) and (min-width: 848px){
	.fimenuhidder{
    display: none;
  }
  .fimenu{
    margin-left: -10px;
    margin-right: 20px;
  }
}*/
@media only screen and (orientation: portrait) and (max-width: 848px){
	.sign_right_btn {
    margin: 10px 0 30px 0;
  }
}
@media only screen and (orientation: portrait) and (min-width: 848px){
	.sign_right_btn {
    margin: -30px 0 30px 0;
  }
}
@media only screen and (orientation: landscape){
  .sign_right_btn {
    margin: -30px 0 30px 0;
  }
}
@media only screen and (orientation: landscape) and (max-width: 1000px){
  .form-scroll{
    overflow-y: scroll;
    height: 70vh;
  }
}


/*Other layouts */
.custom-input{
  border:"1px solid #37708e"
}
.center-vert-horz{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.container-frame {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 51.25%; 
}
.modal-close-btn {
  margin-top: 20px;
  z-index: 1;
  float: right;
  width: 80px;
  height: 30px;
  padding-top: 5px;
  background-color: rgb(49, 177, 121);
  cursor: pointer;
  border-radius: 10px;
}
/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
/*
position: absolute;
    top: 62px;
    left: 25px;
    bottom: 0;
    right: 0;
    width: 90%;
    height: 50%;
*/
.react-confirm-alert-button-group{
  margin-top: 40px;
    margin-left: 100px;
}
.react-confirm-alert-body{

  text-align: center;
}
.center{
  margin: 0 auto;
}
.link {
  @apply text-blue-600;
}

.shadow-outline-white {
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 1) !important;
}

.shadow-outline-black {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 1) !important;
}

.react-switch {
  vertical-align: middle;
  margin-left: 4px;
}

.react-icons {
  vertical-align: middle;
}

.z-1 {
  z-index: 1;
}
a:hover{
  color: #699AEF;
  text-decoration: blink;
}
.z-2 {
  z-index: 2;
}

.bg-1 {
  background: url(/bg-login-1.jpg) no-repeat 0 0 fixed;
  background-size: cover;
}

.w-96 {
  width: calc(0.25rem * 96);
}

.w-128 {
  width: calc(0.25rem * 128);
}

[data-background="light"] {
  .bg-widget {
    @apply bg-white border-grey-100;
    box-shadow: 5px 10px 18px #2196f32e;
  }
}

[data-background="dark"] {
  .bg-widget {
    @apply border;
    background: color(#212121 tint(5%));
    border-color: color(#212121 tint(10%));
  }
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

/*... signin */



.sign_btn {
  background-color: transparent;
  text-align: center;
  border: 2px solid #185A7D;
  padding: 8px 40px;
  border-radius: 30px;
  color: #185A7D;
  text-transform: uppercase;
  width: 150px;
  display: inline-block;
}

.sign_btn:hover {
  color: white;
  background-color: #185A7D;
  cursor: pointer;
  
}
.sticky-column {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0
}
/*.table-container{
  overflow-y: scroll;
  overflow-x: scroll;
  height: 80vh;
  display: block; 
}*/

/*thead{
  display:block;
  position: -webkit-sticky; /* Safari */
/*  position: sticky;
  
}

tbody, tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

tbody {
  display: block;
  overflow: scroll;
  table-layout: fixed;
  height: 83vh;
}


.form-label {
  font-weight: bold;
}
*/
/*Other styles*/

.non-undeline-link{
  text-decoration: none;
}
.non-undeline-link:hover {
  text-decoration: none;
}
.form-control-border{
  border: 1px solid #0f1010;
}
.dotStatus1{
  height: 25px;
  width: 25px;
  background-color: #00e600;
  border-radius: 50%;
  display: inline-block;
}
.dotStatus2{
  height: 25px;
  width: 25px;
  background-color: #e60000;
  border-radius: 50%;
  display: inline-block;
}
.dotStatus3{
  height: 25px;
  width: 25px;
  background-color: gray;
  border-radius: 50%;
  display: inline-block;
}

.custom-add-item-border{
  display: inline-block;
  width: 98.8%;
}
.custom-border{
  left:-5px;
 
}

.custom-border-2{
  margin-left:0px;
  height:100%;
}




/* navbar */



.cab{
  font-size: 22px;
  font-weight: bold;
}

.account_dropdown {
  margin-right: 38px;
  display: flex;
}
.send-information-form{
  background: white;
  padding: 10px 10px 10px 10px;
  border-radius: 10px;
}
.user_state {
  color: #F88C69;
  font-size: 14px;
  font-weight: 800;
  margin-bottom: 0;
  line-height: 1;
  text-transform: uppercase
}

.account_dropdown .user_email {
  color: rgba(158, 158, 158, var(--text-opacity));
  font-weight: 500;
}

.account_dropdown div {
  position: relative;
}

.account_dropdown div::after {
  content: url("../assets/image/web-icons/dropdown.png");
  position: absolute;
  right: 5px;
  top: 7px;
}

/* trip page */
.trip_header {
  padding: 20px 5px;
  flex-wrap: wrap;
}

.trip_header span {
  text-transform: uppercase;
}

.trip_header img {
  max-width: unset;
  display: inline-block;
  margin-right: 30px;
}

.col-6 {
  width: 50%;
}

.mycontent {
  padding-top: 3px;
  padding-left:3px;
  padding-right:3px;
  height: 100%;
}

.trip_box {
  padding: 20px 30px;
  background: #cbd8ec;
  border-radius: 5px;
  height:92%;
  width:99%;
}

.trip_search_box {
  padding: 20px 30px;
  background: #cbd8ec;
  border-radius: 5px;
  /* width:90%; */
}

.trips {
  /*flex-wrap: wrap;*/
  padding-right:"40px"
}
.trips-wrapper{
  flex-wrap: wrap;
}
.trip_title {
  color: #699AEF;
  text-transform: uppercase;
  font-size: bold;
}

.cab-code {
  text-transform: uppercase;
  font-size: 22px;
  padding-bottom:5px;
  margin-left:8px;
  font-weight:bold;
}

.col-3 {
  width: 25%;
  max-width: 23%!important;
  margin: 10px 1%;
}

.col-4 {
  width: 33.33%;
  max-width: 31.33%;
  margin: 10px 1%;
}

.trips label {
  margin: 0 0 6px 10px;
}

.trips input {
  width: 100%;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
}

.trips select {
  width: 100%;
  padding: 7px 7px;
  border-radius: 5px;
  border: "1px solid #37708e";
}

.sorter_btn {
  position: relative;
  padding: 5px 30px;
  background: #699AEF;
  color: white;
  border-radius: 30px;
  text-transform: uppercase;
  height: 34px;
  width: 10em;
}

.sorter_btn svg {
  width: 15px;
  position: absolute;
  left: 10px;
  top: 6px;
  font-size: 18px;
  font-weight: bold;
}

.export_btn {
  padding: 7px 25px;
  background: #39B179;
  color: white;
  border-radius: 16px;
  text-transform: uppercase;
  margin-left: 15px;
  margin-top: 5px;
}
.export_btn :hover {
  background: white;
  color: #39B179;
}
.simple_sorter_btn:active {
  background-image: linear-gradient( gray,gray);
}
.simple_sorter_btn {
  position: relative;
  padding: 2px 10px;
  background: #fff;
  color: #000;
  border-radius: 30px;
  text-transform: uppercase;
  height: 30px;
  width: 10em;
  background-image: linear-gradient( white, gray);
}
.simple_sorter_btn_search{
  margin: 0 auto;
    right: 3%;
    position: relative;
    padding: 2px 10px;
    background: #fff;
    color: #000;
    border-radius: 10px;
    max-height: 32px;
    width: 22vw;
    margin-bottom: 5px;
    text-align: center;
   /* background-image: linear-gradient( #fff,#cbc9da);*/
    text-transform: lowercase;
    border-width: thin;
  
}
.right-pull{
  position: absolute;
  right: 10px;
}
.top-adjust{
  top: -6px;
}
.simple_sorter_btn:hover{
  border-color: #699AEF;
  background-image: linear-gradient(gray, white);
}
.add_btn {
  position: relative;
  padding: 2px 10px;
  background: #699AEF;
  color: white;
  border-radius: 30px;
  text-transform: uppercase;
  margin-left: 15px;
  height: 30px;
  width: 10em;
  box-shadow:inset 0 -0.6em 1em -0.35em rgba(0,0,0,0.17),inset 0 0.6em 2em -0.3em rgba(255,255,255,0.15),inset 0 0 0em 0.05em rgba(255,255,255,0.12);

}

.add_btn:hover {
  background: #FFFFFF;
  color: #699AEF;
}
.add_btn:active {
    top:0.1em;
    box-shadow:inset 0 0.6em 2em -0.3em rgba(0,0,0,0.15),inset 0 0 0em 0.05em rgba(255,255,255,0.12);
}

.table thead tr th {
  position: relative;
  /*background: rgba(224, 224, 224, var(--bg-opacity));*/
  background-color:#37708E ;
  color:white;
}
/*.th {
  color: #2196f3
}*/
.table tbody tr td {
  /*padding-top: 1rem!important;
  padding-bottom: 1rem!important;*/
  border-color: rgb(186 204 204);
}

.table tbody tr:hover {
  background: #FFF4D7;
  cursor: pointer;
}

.trip_section {
  min-height:20vh;
}

.trip_section .table thead tr th::after {
  /*content: url("../assets/image/web-icons/table_arrow.png");*/
  position: absolute;
  right: 5px;
  top: 10px;

}

.trip_section .table thead tr th:first-child::after,
.trip_section .table thead tr th:last-child::after,
.trip_section .table thead tr th:nth-child(13)::after {
  content: "";
}

/* driver page */
.drivers label {
  margin-top: 20px;
}

.inline_btns {
  width: 80px;
}

.inline_btn {
  background: #E8E8E8;
  border-radius: 5px;
  margin: 0 0 7px 10px;
  height: 27px;
  width: 80px;
  text-align: center;
  min-width: 50px;
}
.top-adjustment{
  /* margin-top:-10px; */
  margin-bottom:0;
  height:100%;
}
.inline_btn:hover {
  background: #699AEF;
  color: white;
}
.trip_pdf {
  background: #31b179;
    text-align: center;
    color: white;
    border-radius: 5px;
    padding: 0 10px;
}
.driver_btns {
  display: flex;
  justify-content: flex-end
}

.driver_btns label {
  color: #185A7D;
  font-size: 12px;
  margin-bottom: 8px;
}

.upload_title {
  color: #6B9BEF;
  margin: 0;
  font-size: 20px;
}
.upload_file {
  border-color:#80808082;
  border-style: dashed;
  border-width: medium;
  border-radius: 8px;
    
}
.upload_text {
  margin: 0;
  color: rgba(158, 158, 158, var(--text-opacity));
}


.profile_widget .widget .h-32 {
  height: 11rem !important;
}

.profile_flex {
  align-items: flex-end;
}

.widget:hover{
  cursor: pointer;
}

/* permitHolders page */
.license_checkgroup{
  background: white;
  border-radius: 5px;
  width: 80%;
  border:1px solid #37708e;
}

.license_checkgroup >div{
  font-size: 16px;
  margin: 4px 0;
  padding:6px; 
  cursor:pointer;
  border-bottom: 1px solid #ccc;
}

.license_checkgroup input{
  width: 16px;
  height: 16px;
}

.license_checkgroup span{
  margin-left: 12px;
}

.upload_file {
  background: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lesson_text {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #1C7690;
}

.upload_file input {
  display: none;
}

.custom-file-upload {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #6B9BEF;
  margin-top: 0!important;
}

.custom-file-upload:hover{
  cursor: pointer;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.tooltip{
  display:inline;
  position:relative;  
}
.tooltip:hover:after{
  background: #333;
  background: rgba(0,0,0,.8);
  border-radius: 5px;
  bottom: 26px;
  color: #fff;
  content: attr(title);
  left: 20%;
  padding: 5px 15px;
  position: absolute;
  z-index: 98;
  width: 220px;
}
.tooltip:hover:before{
  border: solid;
  border-color: #333 transparent;
  border-width: 6px 6px 0 6px;
  bottom: 20px;
  content: "";
  left: 50%;
  position: absolute;
  z-index: 99;
}

.settings-container {
  display:flex;
}

.settings-group {
  padding-left:20px;
  margin-top:15px;
  font-size: 20px;
  font-weight:bold;
}

.settings-name {
  flex:6;
  padding-left:20px;
  margin-top:15px;
  margin-left:50px;
  font-size: 14px;
  font-weight:bold;
}
.settings-value {
  flex:3;
  text-align: right;
  margin-top:8px;
}

.settings-value input{
  text-align: right;
  padding-right:20px;
}

.settings-icon{
  flex:0.5;
  text-align: left;
  padding-left:10px;
  padding-top:15px;
  cursor: pointer;
}

.settings-description {
  flex:24;
  padding-left:20px;
  margin-top:15px;
}

.settings-description-slim {
   flex:8;
   padding-left:20px;
   margin-top:15px;
 }
.settings-btn{
  font-size: 12px;
  text-transform: none;
}

@media only screen and (max-width: 1200px) {
  .mycontent{
    padding: 50px;
  }
  .col-4{
    width: 100%;
    max-width: unset;
    margin: 10px 0;
  }
  .license_checkgroup{
    width: 90%;
  }
}

@media only screen and (max-width: 992px) {
  .col-3{
    width: 100%;
    max-width: unset;
    margin: 10px 0;
  }
  .col-6{
    width: 100%;
  }
  .trip_header .col-6{
    text-align: center!important;
    margin: 10px auto;
  }
  .logo .block{
    display: none;
  }
}


.settings_btn {
  background-color: transparent;
  text-align: center;
  border: 1px solid #185A7D;
  padding: 2px;
  border-radius: 30px;
  color: #185A7D;
  width: 100px;
  display: inline-block;
  font-size: 16px;
}

.settings_btn:hover {
  color: white;
  background-color: #185A7D;
  cursor: pointer;
}

.flex-1 {
  flex:1;
}
.flex-2 {
  flex:2;
}
.flex-3 {
  flex:3;
}
.flex-4 {
  flex:4;
}
.flex-5 {
  flex:5;
}
.flex-6 {
  flex:6;
}
.flex-7 {
  flex:7;
}
.flex-8 {
  flex:8;
}






.form-container {
  padding: 20px 30px;
  background: #cbd8ec;
  border-radius: 5px;
  height:92%;
  width:99%;
  overflow-y: auto;
}


.form-container-column {
  padding:10px;
}

.calc-container {
  height: calc(100vh - 50px);
}

.profile-image-container {
  flex: 13;
  background-color: white;
  padding:16px;
  position:relative;
  display: flex;
  align-items: center;
  cursor:pointer;
  border: 1px solid rgb(55, 112, 142);
  border-radius:12px;
}

.profile-image {
  width: 100%;
  height:100%;
  z-index: 2;
}

.profile-image img {
  width: 100%;
  max-height: 26vh;
  object-fit: cover;
  overflow: hidden;
  border:1px solid #aaa;
  border-radius:8px;
}

.profile-image-container .upload-dialog-border {
  padding-top:3vw;
  padding-bottom:3vw;
}

.upload-dialog-border{
  width: 100%;
  height:100%;
  border:2px dashed #888;
  border-radius: 10px;
  cursor: pointer;
  text-align:center;
}
.upload-dialog-border .frame-header {
  font-size: 1.2vw;
  color:#699AEF;
}

.upload-dialog-border .frame-text {
  font-size: 0.6vw;
  color:#888;
}

.document-row {
  margin-top:20px;
 
}

.document-container {
  display:inline-block;
  width:12vw;
  padding:10px;
  vertical-align: top;
}


.document-upload-container {
  flex: 13;
  background-color: white;
  padding:16px;
  position:relative;
  display: flex;
  align-items: center;
  border: 1px solid rgb(55, 112, 142);
  border-radius:12px;
  width:10vw;
  height:8vw;
  text-align: center;
}

.document-upload-container .upload-dialog-border {
  padding-top:1vw;
  padding-bottom:1vw;
  cursor:pointer;
}

.document-icon-container {
  margin: 0 auto;
  cursor:pointer;
}
.document-icon{
  font-size:5vw;
  color: blue ;
}

.document-icon-pdf {
  color: #f40f02 ;
}

.document-icon-image {
  color: green ;
}


.document-delete{
  position: absolute;
  top:5px;
  right:5px;
}
.document-delete-icon{
  font-size:18px;
  color:#888;
  transform: rotate(45deg);
}

.page-content {
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@media (max-width: 1200px) {
  .page-content {
    flex: 100%;
  }
}


@media (max-width: 800px) {
  .page-content {
    flex-direction: column;
  }
}


.menu-container {
  position: relative;
}

.menu {
  background: #ffffff;
  border-radius: 8px;
  position: absolute;
  top: 60px;
  right: 0;
  width: 200px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  z-index:10;
}

.menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu li {
  border-bottom: 1px solid #dddddd;
}

.menu li :hover {
  background-color: #eee;
}


.menu li a {
  text-decoration: none;
  color: #333333;
  padding: 15px 20px;
  display: block;
}

.menu-trigger {
  background: transparent;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  vertical-align: middle;
  z-index:2;
}

.menu-trigger span {
  vertical-align: middle;
  margin: 0 10px;
  font-size:22px;
}

.object-fit-cover{
  object-fit:cover;
}

.permits {
  width:40%;
}


.display-flex {
   display:flex;
}

.flex-1 {
   flex:1;
}
.flex-2 {
   flex:2;
}
.flex-3 {
   flex:3;
}
.flex-4 {
   flex:4;
}


.cab-list-frame{
   width:100%;
   height:580px;
   background-color:white;
   border-radius:4px;
   padding-bottom:20px;
   overflow-y:scroll;
}

.cab-list-heading {
   font-size:1.5rem;
   padding-top:10px;
   padding-bottom:10px;
   padding-left:20px;
}

.cab-list-table-header {
   background-color:#f8f8f8;
   padding-left:10px;
   padding-right:10px;
   padding-top:10px;
   padding-bottom:10px;
}
.cab-list-table-heading {
   font-size:0.9rem;
}

.cab-list-table-data {
   background-color:white;
   padding-left:10px;
   padding-right:10px;
   padding-top:8px;
   padding-bottom:8px;
   border-top: 1px solid #eee;
   cursor:pointer;
}

.queue-size{
   padding-left:3px;
   position:relative;
}

.queue-size span {
   color:white;
   position:absolute;
   font-size:10px;
   font-weight:bold;
   top:3px;
   left:10px;
}

.queue-size-container {
   display:inline-block;
}

.queue-size-container span {
   top:0;
}

.cab_operating_type{
   background-color:white;
   border-radius:5px;
   border:1px solid rgb(55, 112, 142);;
   padding:12px;
   padding-left:18px;
   font-size:1rem;
   color:#495057;
   line-height: 1.5;
}

.cab_operating_type input {
   margin-right:10px;
}

.google-icon {
  height:40px;
  display:inline-block;
  margin-top:-10px;
}

/* qr code part */
.card-border {
 /* border: 2px solid #37708e;*/
 /* border: 2px solid white; */
  border: 2px solid #37708e1c;
  padding: 20px;
  border-radius: 4px;
}

.qr-code-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding-top: 30px;
    width:33.3%;
    min-width: 500px;
    min-height: 400px;
}

@media only screen and (max-width: 1200px) {
  .qr-code-wrapper {
    width:100%;
    min-width: auto;
  }
}
/* message page */
.car-picker {
  height:200px;
  background-color: white;
  overflow:auto;
}

.cab-choosen {
  background-color: #5bb65b !important;
}
.choose-car-btn {
  font-size: 13px;
  color: #185A7D;
  font-weight: 500;
  text-transform: capitalize;
  cursor: pointer;
}

.car-picker-btn {
  display: flex;
  justify-content: center;
  justify-items: center;
  text-align: center;
  width: 100%;
}

.message-form label {
  font-size: 1.1rem;
  margin-top: 20px;
}

.sending-data {
  color: green;
}

.car-picker .makeStyles-tableColLayout-52 {
  text-align: center;
}

.cab-checkbox-container{
  margin-top:10px;
}

.checkbox-label {
  margin-left:10px;
  margin-top:5px;
}

@media only screen and (max-width: 1400px) {
  .message-form-size {
    width:100%;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 2000px){
  .message-form-size {
    width:50%;
  }
}
@media only screen and (min-width: 2000px) and (max-width: 3000px){
  .message-form-size {
    width:40%;
  }
}
@media only screen and (min-width: 3000px) {
  .message-form-size {
    width: 30%;
  }
}

@media only screen and (max-width: 2000px) {

  .cab-driver-icon-wrapper {
    display:block;
  }

  .cab-driver-icon {
    display:flex;
    align-items: center;
    flex-wrap: wrap;
    
  }
}

@media only screen and (min-width: 2000px) {

  .cab-driver-icon-wrapper {
    display:flex;
    align-items: center;
  }

  .cab-driver-icon {
    display:inline-flex;
    align-items: center;
    margin-left:5px;
  }
}

.fullscreen-spinner{
  position:relative;
  top: 40%;
  left:60%;
  background-color: 0 0 0 20;
}